/*
  Dropdown
  for Bootstrap 4.0
  -------------------
  PRH Style Guide
*/

.dropdown-item {
  text-decoration: underline;
  font-family: "FagoWebPro-Medium";
  font-weight: 500;
  &:hover,
  &:focus,
  &:active {
    background-color: inherit;
    text-decoration: underline;
  }
  &.active {
    text-decoration: underline;
    background-color: $prh-blue;
    color: $white;
    &:hover {
      background-color: $prh-purple;
    }
    &:active,
    &:focus {
      box-shadow: 0px 0px 0px 2px $prh-blue, inset 0px 0px 0px 1px $white;
      background-color: $prh-purple;
      border-color: $white;
    }
  }
}

.theme-ytj {
  .dropdown-toggle:not(.btn) {
    &:after {
      background-color: darken($ytj-bluish-green, 5%);
    }
    &:hover:after {
      background-color: darken($ytj-bluish-green, 10%);
    }
    &:active:after {
      background-color: darken($ytj-bluish-green, 50%);
    }
  }
}

.dropdown-toggle:not(.btn) {
  &:after {
    border: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='3' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 0.75rem;
    background-position: center;
    background-color: darken($prh-blue, 5%);
    margin-left: 0.5rem;
    width: 1.5rem;
    height: 1.1rem;
    border-radius: 2px;
    vertical-align: middle;
  }
  &:hover:after {
    background-color: darken($prh-blue, 10%);
  }
  &:active:after {
    background-color: darken($prh-blue, 50%);
  }
}

.dropdown {
  display: block;
  margin-top: 0; // remove the gap so it doesn't close
}

/* Bootstrap defaults override */
.show > .btn-secondary.dropdown-toggle {
  background-color: $white;
  border-color: $prh-blue;
  color: $prh-purple;
  &::after {
    border-top: 0;
    border-bottom: 0.3em solid;
  }
}
