/*
  Alerts
  for Bootstrap 4.0
  -------------------
  PRH Style Guide

*/

.theme-ytj {
  .alert {
    button:focus {
      outline: 2px solid $ytj-bluish-green;
    }
  }
}

.alert {
  color: $prh-dark-gray;
  --paddingleft: 4rem;
  background-size: 2rem;
  background-position: 1rem 1rem;
  background-repeat: no-repeat;
  min-height: 4rem;
  padding-left: var(--paddingleft);
  .h4 {
    color: $prh-dark-gray;
    margin-bottom: 1rem;
  }
  .prh-icon {
    position: absolute;
    left: 1rem;
  }
  button:focus {
    outline: 2px solid $prh-blue;
  }
}

.alert__secondary_danger {
  background-image: none;
  border-color: $prh-dark-gray;
  padding-left: 5rem;
  box-shadow: inset var(--paddingleft) 0px 0px 0px $red;
  position: relative;
  .prh-icon {
    position: absolute;
    top: 30%;
  }
}

.alert-danger {
  background-image: none;
}

.alert-info {
  background-image: none;
}

.alert-success {
  background-color: #daeec6;
  background-image: none;
}

.alert-warning {
  background-color: #f7f0a1;
  background-image: none;
}

.alert-dismissible .close {
  padding-top: 0.5rem;
}
