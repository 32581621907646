.prh-no-image {
  stroke: #b1b1b1;
  stroke-width: 12px;
  width: 100%;
  height: auto;
  margin: 0;
  padding-top: 0;
  border: 0;
}

.prh-card {
  a {
    color: #5d5d5d;
  }
  a:hover {
    text-decoration: none;
  }
  .datafield-content {
    span {
      color: #035ca7;
    }
    span:hover {
      text-decoration: underline;
    }
  }
  border: 1px solid $prh-medium-gray;
}

.prh-table-row {
  cursor: pointer;
  span {
    color: #035ca7;
  }
  span:hover {
    text-decoration: underline;
  }
}
.maincontent-header-background {
  background-color: $prh-pale-blue;
  height: 180px;
  
}

.dropdown-menu {
  max-height: 250px;
  overflow-y: auto;
}
