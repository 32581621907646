/*
  Pagination
  for Bootstrap 4.0
  -------------------
  PRH Style Guide

*/

// overrides Bootstrap pagination default colours
.page-item {
  .page-link {
    font-family: "FagoWebPro-Medium";
    font-weight: 500;
    text-decoration: none !important;
    &.btn-primary:hover,
    &.btn-primary:focus,
    &.btn-primary:active {
      background-color: $prh-purple;
      border-color: $prh-purple;
    }
    .disabled,
    .btn-secondary.disabled {
      border-color: $prh-light-gray;
      .page-link {
        color: $prh-dark-gray-50;
      }
    }
    &.btn-secondary {
      &:focus {
        box-shadow: 0px 0px 0px 2px $white, inset 0px 0px 0px 2px $prh-blue;
        border-color: $prh-blue;
        outline: 0px;
        z-index: 5;
      }
    }
  }
  &.active {
    .page-link {
      background-color: $prh-blue;
      border-color: $prh-blue;
      &:focus {
        border-color: $white;
      }
    }
  }
}

.theme-ytj {
  //overrides $pagination-color
  .page-link {
    color: $ytj-turquoise;
    &:focus {
      box-shadow: 0px 0px 0px 2px $ytj-bluish-green;
    }
    &.btn-secondary:not(:disabled):not(.disabled):active {
      background-color: $ytj-turquoise;
      border-color: $ytj-turquoise;
    }
  }
  //overrides $pagination-hover-border-color, $pagination-hover-bg
  .page-link:hover {
    background-color: $ytj-purple;
    border-color: $ytj-purple;
    color: $white;
  }
  //overrides $pagination-active-bg, $pagination-active-border-color
  .page-item.active .page-link {
    background-color: $ytj-bluish-green;
    border-color: $ytj-bluish-green;
    &:hover {
      background-color: $ytj-purple;
      border-color: $ytj-purple;
    }
    &:focus {
      outline-color: $ytj-bluish-green;
    }
  }
  // overrides $jumbotron-bg
  .jumbotron {
    background-color: $ytj-turquoise10;
  }
}

.prh-pagination {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.prh-pagination__page-count {
  margin: 0.5rem 1rem;
  color: $prh-dark-gray-87;
}

