/*
  Tables
  for Bootstrap 4.0
  -------------------
  PRH Style Guide

*/

.theme-ytj {
  th,
  .b-table-stacked-md > tbody > tr > [data-label]::before {
    color: inherit;
    background-color: $ytj-turquoise10;
    a,
    button {
      color: $ytj-bluish-green;
    }
  }
}

th,
.b-table-stacked-md > tbody > tr > [data-label]::before {
  font-family: "FagoWebPro-Medium";
  font-weight: 500;
  color: inherit;
  background-color: $prh-tint-topaz;
  a,
  button {
    color: $prh-blue;
  }
}

.table-hover tbody tr:hover {
  color: $prh-dark-gray-87;
}

.prh-table__controls {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.prh-table__page-count {
  margin: 0.5rem 1rem;
  font-family: "FagoWebPro-Medium";
  font-weight: 500;
  color: $prh-dark-gray-50;
}

// Smaller fonts and padding in responsive stacked table
@media (max-width: 768px) {
  .b-table-stacked-md {
    font-size: 0.8rem;
    td {
      padding: 0.25rem;
    }
  }
}

/*
 * Cross-browser unification for checkboxes in 
 * Bootstrap-Table.js managed tables. 
 * Styling definied in Forms/Checkboxes does not
 * work out of the box in a small space like this. 
 * So, rewriting all checkbox rules. 
*/
.theme-ytj {
  .custom-table-with-checkbox-rows {
    input[type="checkbox"]::before {
      background-color: $ytj-bluish-green;
    }
    input[type="checkbox"]:checked {
      background-color: $ytj-bluish-green;
      border-color: $ytj-bluish-green;
    }
    input[type="checkbox"]:focus {
      box-shadow: 0px 0px 0px 2px $ytj-bluish-green;
      border-color: $ytj-bluish-green;
    }
  }
}
.custom-table-with-checkbox-rows {
  input[type="checkbox"] {
    appearance: none;
    width: 1em;
    height: 1em;
    border: 1px solid $prh-dark-gray-87;
    border-radius: 0px;
    display: grid;
    place-content: center;
  }
  input[type="checkbox"]::before {
    content: "";
    width: 0.75em;
    height: 0.75em;
    transform: scale(0);
    background-color: $prh-blue;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
  }
  input[type="checkbox"]:checked {
    background-color: $prh-blue;
    border-color: $prh-blue;
  }
  input[type="checkbox"]:checked::before {
    transform: scale(1);
  }
  input[type="checkbox"]:focus {
    box-shadow: 0px 0px 0px 2px $prh-blue;
    border-color: $prh-blue;
    outline: 0px;
  }
  input[type="checkbox"]:disabled {
    cursor: not-allowed;
    background-color: $prh-lightest-gray;
  }
}
