/*
  Forms
  for Bootstrap 4.0
  -------------------
  PRH Style Guide

*/
.theme-ytj {
  .prh-form__container > .prh-section-borders {
    border-top: 4px solid $ytj-bluish-green;
  }
  .was-validated
    .custom-control-input:valid:checked
    ~ .custom-control-label::before,
  .custom-control-input.is-valid:checked ~ .custom-control-label::before {
    background-color: $ytj-bluish-green !important;
    border-color: $ytj-bluish-green;
  }
  .was-validated
    .custom-control-input:valid:checked:focus
    ~ .custom-control-label::before,
  .custom-control-input.is-valid:checked ~ .custom-control-label::before {
    box-shadow: 0px 0px 0px 2px rgba($ytj-turquoise, 1) !important;
  }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: $ytj-bluish-green;
    box-shadow: 0px 0px 0px 2px $ytj-bluish-green;
  }
  .was-validated .form-control:invalid:focus,
  .form-control.is-invalid:focus {
    box-shadow: 0px 0px 0px 2px rgba($danger, 1) !important;
    border-color: $danger;
  }
  input[type="radio"]:focus + label {
    outline: 2px solid $ytj-bluish-green;
  }
}

.prh-form {
  background-color: $prh-light-blue;
  padding-bottom: 2rem;
  h2 {
		margin-top: 1rem;
		border-top: 1px solid $prh-light-gray;
		padding-top: 1rem;
	}
}

.prh-form .prh-form__container {
  padding: 1rem;
  max-width: $container-max-width;
  margin: auto;
}

.prh-form__container > .prh-section-borders {
  border-top: 4px solid $prh-blue;
  margin: 0;
}

.prh-form .container {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.prh-form-title {
  border-top: 2px solid $prh-medium-gray;
  min-height: 3rem;
  margin-top: 1rem;
}

/**
 *  Form elements
 */

label,
.prh-form__price,
.custom-select {
  font-family: "FagoWebPro-Medium";
}

.form-container {
  padding: 1rem 2rem;
}

/* Switch fonts to body fonts in lists */

.form-control {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

/*
 *  Form required color
 */

.required::after {
  color: $red;
  content: "*";
}

/*
 * Custom file uploader styles
*/

.custom-file-label::after {
  text-transform: uppercase;
}

/* 
Validated checkbox styles
*/
.was-validated
  .custom-control-input:valid:checked
  ~ .custom-control-label::before,
.custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: $prh-blue !important;
  border-color: $prh-blue;
}

.was-validated
  .custom-control-input:invalid:focus
  ~ .custom-control-label::before {
  box-shadow: 0px 0px 0px 2px rgba($danger, 1) !important;
}

.was-validated
  .custom-control-input:valid:checked:focus
  ~ .custom-control-label::before,
.custom-control-input.is-valid:checked ~ .custom-control-label::before {
  box-shadow: 0px 0px 0px 2px rgba($prh-bright-blue, 1) !important;
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  box-shadow: 0px 0px 0px 2px rgba($danger, 1) !important;
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
  box-shadow: 0px 0px 0px 2px rgba($success, 1) !important;
}

.was-validated .custom-control-input:valid ~ .custom-control-label,
.custom-control-input.is-valid ~ .custom-control-label {
  color: $body-color;
}

/* Validated focus styles in inputs */

.was-validated .form-control:invalid:focus {
  box-shadow: 0px 0px 0px 2px rgba($danger, 1);
}

.was-validated .form-control:valid:focus {
  box-shadow: 0px 0px 0px 2px rgba($success, 1);
}

/* Fix BS custom checkbox default active state */
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  background-color: $prh-blue;
  border-color: $prh-blue;
}

/* Disabled styling for aria-disabled */

input[aria-disabled="true"] + label {
  cursor: not-allowed;
  color: $prh-dark-gray-50;
  display: inline-block;
  pointer-events: none;
  text-decoration: none;
  &::before {
    background-color: $prh-medium-gray;
  }
}

input[aria-disabled="true"] {
  cursor: not-allowed;
  color: $prh-dark-gray-50;
  display: inline-block;
  pointer-events: none;
  text-decoration: none;
}

input[type="radio"]:focus + label {
  outline: 2px solid $prh-blue;
}

/*
 * Select list with text filter
 */
.select-textfilter {
  position: relative;
  .autocomplete-suggestion:hover,
  .focused {
    outline: 2px solid $prh-blue;
  }
  .textfilter-suggestions {
    position: absolute;
    width: 100%;
    background-color: $prh-lightest-gray;
    & > div {
      width: 100%;
      padding: 5px 0.5rem;
      border-left: 1px solid $prh-dark-gray-87;
      border-right: 1px solid $prh-dark-gray-87;
      &:last-child {
        border-bottom: 1px solid $prh-dark-gray-87;
      }
    }
  }
}
.search-string-match {
  background-color: $prh-yellow;
}
.multiselectSelectedItem {
  padding: 0.25rem 0.5rem;
  margin: 0.25rem;
  /*background-color: $prh-medium-gray;*/
  a {
    color: $white;
  }
}
.multiselectorWrap {
  padding: 0.5rem;
  .badge-secondary {
    font-size: 1rem;
    border-radius: 0px;
    background-color: $white;
    border: 1px solid $prh-blue;
    color: $prh-blue;
    margin-top: 3px;
    margin-bottom: 3px;
    padding: 0.25rem 0px 0px 0.25rem;
    &:hover,
    &:focus {
      border-color: $prh-purple;
      color: $prh-purple;
      outline: 0px;
    }
    &:focus {
      box-shadow: 0px 0px 0px 3px $prh-blue;
    }
    svg {
      margin-top: -2px;
    }
  }
}
.select-textfilter {
  .textfilter-suggestions {
    background-color: $prh-pale-blue;
    color: $black;
    & > div {
      border-color: $prh-blue;
    }
  }
}

/* used when we need an actual input field inside an 
    area that already looks like an input, 
    for instance, the form/multiselector component */
.form-control-inline {
  display: inline-block;
  width: auto;
  height: auto;
  border: 0px solid transparent;
  padding: 0px;
  &:focus {
    box-shadow: 0px 0px 0px 0px transparent;
  }
}
/* to make the wrapper look like a focused element */
.form-control-inline-parent-focus {
  background-color: $prh-pale-blue;
  border-color: $prh-blue;
  box-shadow: 0px 0px 0px 2px $prh-blue;
}
.auto-height {
  height: auto !important;
}

/* Fallback focus styling in case you need it */

// textarea:focus,
// input[type="text"]:focus,
// input[type="password"]:focus,
// input[type="datetime"]:focus,
// input[type="datetime-local"]:focus,
// input[type="date"]:focus,
// input[type="month"]:focus,
// input[type="time"]:focus,
// input[type="week"]:focus,
// input[type="number"]:focus,
// input[type="email"]:focus,
// input[type="url"]:focus,
// input[type="search"]:focus,
// input[type="tel"]:focus,
// input[type="color"]:focus,
// input[type="checkbox"]:focus,
// .uneditable-input:focus {
//   box-shadow: 0px 0px 0px 2px rgba($prh-bright-blue, 1) !important;
// }
