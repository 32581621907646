/*
  Form Headers
  for Bootstrap 4.0
  -------------------
  PRH Style Guide

*/

.prh-form-header__title {
  margin-bottom: 0;
}
.prh-form-header__subtitle {
  font-family: "FagoWebPro-Medium";
  color: $prh-dark-gray-50;
  font-size: 0.9rem;
}
