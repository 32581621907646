/*
  Buttons
  for Bootstrap 4.0
  -------------------
  PRH Style Guide

*/

.btn {
  & > *:last-child {
    margin-left: 0.5rem;
  }
  & > *:first-child {
    margin-right: 0.5rem;
  }
  .prh-icon {
    height: 1rem;
    width: 1rem;
    padding: 0;
  }
  .badge {
    line-height: 1;
  }
}
.badge {
  &.hasLink {
    position: relative;
    padding-right: 1.25rem;
    .close {
      position: absolute;
      font-size: 1rem;
      float: none;
      top: 0.1rem;
      right: 0.25rem;
      opacity: 1;
      width: 0.9rem;
      height: 0.9rem;
      line-height: 0.85rem;
      color: $prh-dark-gray !important;
      &:focus,
      &:hover {
        color: $prh-purple !important;
      }
      &:focus {
        outline: 2px solid $prh-blue;
      }
      &:active {
        color: $prh-dark-gray !important;
      }
    }
  }
}
a.badge-primary {
  &:hover,
  &:focus,
  &:active {
    background-color: $prh-purple !important;
  }
  &:focus,
  &:active {
    box-shadow: 0px 0px 0px 2px $prh-blue, inset 0px 0px 0px 1px $white;
    border-color: $white;
  }
}
.badge-secondary {
  color: $prh-dark-gray;
  background-color: $prh-light-gray;
}

.theme-ytj {
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0px 0px 0px 2px $ytj-bluish-green;
  }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: $ytj-bluish-green;
  }
  .btn {
    &:focus {
      box-shadow: 0px 0px 0px 2px rgba($ytj-turquoise, 1) !important;
    }
    &:hover {
      .prh-icon {
        stroke: $ytj-purple;
      }
      .prh-icon--white {
        stroke: $white;
        a:hover &,
        a:active &,
        .btn:hover &,
        .btn:active & {
          stroke: $white;
        }
      }
    }
  }
  .btn.btn-primary {
    background-color: $ytj-bluish-green;
    border-color: $ytj-bluish-green;
    &:focus,
    &:hover {
      background-color: $ytj-purple;
      border-color: $white;
    }
    &:active {
      background-color: $ytj-purple;
      border-color: $ytj-bluish-green;
    }
  }
  .btn-primary--light {
    background-color: $ytj-turquoise;
    border-color: $ytj-turquoise;
  }
  .btn.btn-secondary.dropdown-toggle {
    &:active {
      background-color: $ytj-purple;
      color: $white;
    }
    &:focus {
      background-color: $ytj-purple;
      color: $white;
    }
    &:hover {
      //background-color: $ytj-purple;
      //color: $white;
    }
  }

  .btn.btn-secondary {
    color: $ytj-bluish-green;
    border-color: $ytj-bluish-green;
    &:hover {
      border-color: $ytj-purple;
      color: $ytj-purple;
    }
    &:active,
    &:focus {
      background-color: transparent;
      color: $ytj-purple;
    }
  }
  .btn-link {
    color: $ytj-bluish-green;
  }
  .btn-icon {
    &:hover,
    &:active,
    &:focus {
      border-color: $ytj-purple;
      color: $ytj-purple;
    }
  }
  /* Badges as buttons */
  .badge-overlay {
    a:hover &,
    a:active &,
    .btn:hover &,
    .btn:active {
      background-color: $ytj-purple;
    }
  }
  .badge-primary {
    background-color: $ytj-bluish-green;
  }
  .badge-secondary {
    background-color: $ytj-turquoise;
  }
  .badge-info {
    background-color: $ytj-bluish-green;
  }
  .prh-site-header .btn-primary,
  .bg-primary .btn-primary {
    background-color: $ytj-turquoise50;
    border-color: transparent;
    &:hover,
    &:focus,
    &:active {
      background-color: $ytj-purple;
      border-color: $ytj-bluish-green;
    }
    &:focus {
      box-shadow: 0px 0px 0px 3px rgba($white, 1) !important;
      background-color: $ytj-purple;
    }
  }

  .bg-primary .btn-secondary {
    &:active,
    &:focus {
      background-color: white; //$ytj-turquoise50;
      box-shadow: 0px 0px 0px 3px rgba($white, 1),
        inset 0px 0px 0px 1px rgba($ytj-bluish-green, 1) !important;
      .prh-icon--blue {
        stroke: $ytj-purple;
      }
    }
  }

  .btn-tertiary {
    color: $ytj-bluish-green;
    &:focus {
      box-shadow: 0px 0px 0px 2px rgba($ytj-bluish-green, 1) !important;
      color: $ytj-purple;
    }

    &:hover {
      border: 1px solid $ytj-purple;
      text-decoration: none;
      color: $ytj-purple;
    }
    &.btn-tertiary-icon {
      &:hover {
        border: 1px solid transparent;
      }
    }
  }

  .btn-link {
    color: $ytj-bluish-green;
    &:focus {
      box-shadow: 0px 0px 0px 2px rgba($ytj-bluish-green, 1) !important;
      color: $ytj-purple;
    }
    &:hover {
      border: 1px solid $ytj-purple;
      text-decoration: none;
      color: $ytj-purple;
    }
  }
}

.btn {
  font-family: "FagoWebPro-Medium";
  font-weight: 500;
  margin-bottom: 6px;
  margin-top: 6px;
  min-width: 7rem;
  letter-spacing: 0.015rem;
  /*&:focus {
    box-shadow: 0px 0px 0px 2px rgba($prh-blue, 1);
    position: relative;
    z-index: 1;
  }*/
  // space between primary and secondary buttons
  & {
    margin-right: 20px;
  }
  // space between tertiary button and any other button
  &.btn-tertiary {
    margin-right: 10px;
  }
  // finally, for the last have no right margin at all
  &:last-child {
    margin-right: 0px;
  }
}

.btn-fluid {
  margin: 0;
}
// prepare attributes sets for primary button states
@mixin btn-primary-hover {
  background-color: $prh-purple;
  border-color: $prh-purple;
}
@mixin btn-primary-focus {
  box-shadow: 0px 0px 0px 2px $prh-blue, inset 0px 0px 0px 1px $white;
  background-color: $prh-purple;
  border-color: $white;
  .prh-icon {
    stroke: $white;
  }
}
// primary button rules
.btn-primary {
  background-color: $prh-blue;
  border-color: $prh-blue;
  text-transform: uppercase;
  letter-spacing: 0.06rem;
  &:hover {
    @include btn-primary-hover;
  }
  &:focus,
  &:active {
    @include btn-primary-focus;
  }
  &:disabled,
  &.disabled {
    box-shadow: 0px 0px 0px 0px $prh-dark-gray-50;
    background-color: $prh-dark-gray-50;
    border-color: $prh-dark-gray-50;
  }
}
.prh-site-header .btn-primary,
.bg-primary .btn-primary {
  background-color: $prh-bright-blue;
  border-color: $prh-bright-blue;
  &:hover {
    @include btn-primary-hover;
  }
  &:focus,
  &:active {
    @include btn-primary-focus;
  }
}

.btn-primary--lowercase {
  text-transform: none;
  letter-spacing: 0.015rem;
}

.btn-primary--light {
  color: $white;
  background-color: $prh-bright-blue;
  border-color: $prh-bright-blue;
  text-transform: none;
  letter-spacing: 0.015rem;
  @media (max-width: 767px) {
    padding: 8px;
  }
}

// prepare attributes sets for secondary button states
@mixin btn-secondary-hover {
  background-color: $white;
  border-color: $prh-purple;
  color: $prh-purple;
}
@mixin btn-secondary-focus {
  box-shadow: 0px 0px 0px 2px $white, inset 0px 0px 0px 2px $prh-blue;
  background-color: $white;
  border-color: $prh-blue;
  color: $prh-purple;
}
// secondary button rules
.btn-secondary {
  background-color: $white;
  color: $prh-blue;
  border-color: $prh-blue;
  &:hover {
    @include btn-secondary-hover;
  }
  &:active,
  &:focus {
    @include btn-secondary-focus;
    .prh-icon--blue {
      stroke: $prh-purple;
    }
  }
  &:disabled,
  &.disabled {
    color: $prh-dark-gray-50;
    background-color: $white;
    border-color: $prh-dark-gray-50;
  }
}

// prepare attributes sets for tertiary button states
@mixin btn-tertiary-hover {
  border-color: $prh-purple;
  color: $prh-purple;
}
@mixin btn-tertiary-focus {
  box-shadow: 0px 0px 0px 0px $prh-blue;
  border-color: $prh-blue;
  color: $prh-purple;
}
// tertiary button rules
.btn-tertiary {
  color: $prh-blue;
  &:hover {
    @include btn-tertiary-hover;
  }
  &:focus {
    @include btn-tertiary-focus;
  }
}

.btn-icon {
  color: $white;
  &:hover,
  &:active,
  &:focus {
    background-color: $white;
    color: $prh-purple;
  }
  &:hover,
  &:active {
    border-color: $prh-purple;
  }
  &:disabled,
  &.disabled {
    color: $prh-dark-gray-50;
    text-decoration: none;
  }
}

// prepare attributes set for tertiary icon states
@mixin btn-tertiary-icon-hover {
  color: $prh-purple;
}
@mixin btn-tertiary-icon-focus {
  color: $prh-purple;
}
// tertiary button with icon rules
.btn-tertiary-icon {
  min-width: 0;
  /*padding: 0.125rem;*/
  > *:last-child {
    margin-left: 0px;
  }
  &:hover {
    @include btn-tertiary-icon-hover;
  }
  &:focus {
    @include btn-tertiary-icon-focus;
    svg {
      stroke: $prh-purple;
    }
  }
}

// Used in collapse
.btn-link--gray {
  background-color: $prh-lightest-gray;
  border: 1px solid $prh-medium-gray;
}

.btn-link-fluid {
  padding: 0;
}

/* Close button color override to ensure contrast */

.close {
  color: #000000 !important;
}

/* Tooltips */

.tooltip-btn {
  border-radius: 9px;
  border-width: 1px;
  width: 18px;
  height: 18px;
  padding: 0;
  position: relative;
  font-size: 12px;
  text-align: center;
  margin: 0 0.5rem 0.25rem 0.5rem;
  line-height: 17px;
  span {
    display: block;
    width: 100%;
    top: 0;
    right: 0;
    position: absolute;
  }
}

.btn-popover,
.btn-tooltip,
.btn-icon {
  padding: 0;
  min-width: 0;
  &.btn-lg .prh-icon {
    height: 2.4rem;
    width: 2.4rem;
    padding: 0.5rem;
  }
  .prh-icon {
    height: 1.6rem;
    width: 1.6rem;
    padding: 0.25rem;
    margin: 0;
  }
  label & .prh-icon {
    height: 1.5rem;
  }
}
.btn-popover {
  &.btn-lg .prh-icon {
    padding: 0.4rem 0.5rem 0.6rem 0.5rem;
  }
}

.btn-icon-solo {
  padding: 0;
  min-width: 0;
  text-decoration: none;
  transition: none;
  /* Due consistency, this was proposed but needs to be re-evaluated 
     based on real needs // needs clearer definition for what is 
     btn-icon-solo actually used for. 
     Leaving the consistency rules here for future release. */
  /*&:hover,
  &:focus,
  &:active {
    color: $prh-purple;
    .prh-icon--blue {
      stroke: $prh-purple;
    }
    .badge-overlay {
      background-color: $prh-purple;
    }
  }
  &:focus {
    outline: 2px solid $prh-blue;
  }*/
  /* End of abovementioned concsistency rules preservement */
  &:focus,
  &:active {
    outline: 2px solid $prh-blue;
    .prh-icon--blue {
      stroke: $prh-blue;
    }
  }
  &:hover {
    .prh-icon--blue {
      stroke: $prh-purple;
    }
  }
  .bg-primary &:focus,
  .bg-primary &:active {
    .prh-icon--white {
      stroke: $white;
    }
  }
}

/* A long list of general button active state rules to override BS defaults 
 * This is pretty long, because BS makes it that way.
 */
.btn-primary:not(:disabled):not(.disabled):active {
  background-color: $prh-purple;
  border-color: $white;
}
.bg-primary .btn-primary:not(:disabled):not(.disabled):active {
  border-color: $white;
}
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0px 0px 0px 2px $prh-blue, inset 0px 0px 0px 1px $white;
}
.btn-secondary:not(:disabled):not(.disabled):active {
  background-color: $white;
  border-color: $prh-blue;
  color: $prh-purple;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0px 0px 0px 2px $white, inset 0px 0px 0px 2px $prh-blue;
}

.theme-ytj {
  .btn-primary:not(:disabled):not(.disabled):active {
    background-color: $ytj-purple;
    border-color: $white;
  }
  .bg-primary .btn-primary:not(:disabled):not(.disabled):active {
    border-color: $ytj-bluish-green;
  }
  .btn-secondary:not(:disabled):not(.disabled):active {
    background-color: $white;
    border-color: $ytj-purple;
    color: $ytj-purple;
  }
}

/* Badges as buttons */

.badge-overlay {
  position: absolute;
  left: 3.5em;
  top: 0.5em;
  text-decoration: none !important;
  transition: none;
  a:hover &,
  a:active &,
  .btn:hover &,
  .btn:active {
    color: $white;
    background-color: $prh-purple;
  }
}

.badge-info {
  color: $white;
  background-color: $prh-blue;
}

.badge-notification {
  background-color: $prh-medium-purple;
}
