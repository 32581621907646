/*
  Cards
  for Bootstrap 4.0
  -------------------
  PRH Style Guide

*/

.theme-ytj {
  .border-primary {
    border-color: $ytj-bluish-green !important; // this has been defined with !important flag somewhere else, sadly this is the only way to override it
  }
  .prh-cards {
    .card-img-top {
      background-color: $ytj-turquoise10;
    }
    .border-primary {
      &,
      &:first-child,
      &:nth-child(4n + 0),
      .list-group-item {
        border: 1px solid $ytj-bluish-green !important; // this has been defined with !important flag somewhere else, sadly this is the only way to override it
      }
    }
  }
}

.prh-cards {
  display: flex;
  flex-wrap: wrap;
  @supports (display: grid) {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(auto, 16rem));
    grid-gap: 1rem 3rem;
  }
  .card {
    display: flex;
    align-items: stretch;
    width: calc(100% / 3);
    min-width: 15rem;
    padding: 2rem;
    @supports (display: grid) {
      width: auto;
      min-width: initial;
      padding: 0;
    }
    border: 0;
    &:first-child,
    &:nth-child(4n + 0) {
      border-left: 0;
    }
  }
  .card-img-top {
    width: 6rem;
    height: 6rem;
    padding: 0.5rem;
    border-radius: 3rem;
    background-color: $prh-pale-blue;
    display: flex;
    align-items: center;
    img {
      margin: auto;
      width: 100%;
      max-height: 5.5rem;
      max-width: 5.5rem;
      height: auto;
      opacity: 0.8;
      display: block;
    }
    svg {
      margin: auto;
    }
  }
  .purple.card-img-top {
    background-color: rgba($prh-medium-purple, 0.5);
  }
  .card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem 0;
  }
  .border-primary {
    &,
    &:first-child,
    &:nth-child(4n + 0),
    .list-group-item {
      border: 1px solid $prh-blue;
    }
  }
  &.card-authorizationbox {
    grid-template-columns: repeat(auto-fit, minmax(auto, 20rem));
    h2 {
      font-size: 1.1rem;
      margin-bottom: 0px;
      color: $prh-dark-gray-87;
    }
    .border-primary {
      border-width: 2px;
    }
    .list-group-item {
      border-width: 0 0 2px;
      &:last-child {
        border-width: 0;
      }
    }
  }
}

.prh-small-cards {
  display: flex;
  flex-wrap: wrap;
  @supports (display: grid) {
    display: grid;
    padding: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(auto, 15rem));
    grid-gap: 1rem;
    justify-content: center;
  }
  .card {
    width: calc(100% / 4);
    min-width: 10rem;
    @supports (display: grid) {
      width: auto;
      min-width: initial;
    }
  }
  .card-text {
    font-size: 0.9rem;
    font-family: "FagoWebPro-Medium";
    text-align: center;
  }
  .card-img-top {
    margin: 0 auto;
  }
}
.prh-pricebox {
  &,
  .prh-pricebox-content {
    width: 19rem;
    max-width: 100%;
  }
  button {
    width: 100%;
    text-align: left;
    padding-left: 0.75rem;
    margin-bottom: 0px;
  }
  .prh-pricebox-content {
    position: absolute;
    border: 1px solid $prh-dark-gray-38;
    border-top: 0px;
    background-color: $white;
    padding: 0.75rem 0.75rem 0rem 0.75rem;
    .border-top,
    .border-bottom {
      border-color: $prh-dark-gray-38 !important;
    }
    dl {
      margin-left: 0.25rem;
      margin-right: 0.25rem;
      dt {
        font-weight: 400;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
          "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
          "Segoe UI Emoji", "Segoe UI Symbol";
      }
    }
  }
}
