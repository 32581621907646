/*
  Step indicator
  for Bootstrap 4.0
  -------------------
  PRH Style Guide

*/

.theme-ytj {
  .nav-tabs {
    .nav-link {
      &.active {
        background-color: $ytj-bluish-green;
      }
      &:active {
        border: 1px solid $ytj-purple;
        background-color: $ytj-purple;
      }
      &:focus {
        box-shadow: 0px 0px 0px 2px rgba($ytj-turquoise, 1) !important;
      }
      &.error:hover {
        color: $white;
        border: 1px solid $ytj-purple;
      }
    }
  }
  .step-indicator {
    .nav-link {
      border-color: $ytj-bluish-green;
      color: $ytj-bluish-green;
      &:focus {
        outline: $ytj-bluish-green;
      }
      &:hover {
        background-color: inherit !important;
        color: inherit;
        color: $ytj-purple;
      }
      &.success {
        color: $prh-green;
        &:hover {
          color: $prh-purple;
        }
      }
      &.error {
        &:hover {
          color: $prh-purple;
        }
      }
    }
    .static-item {
      .nav-link {
        border-color: $prh-light-gray;
        &:hover,
        &.success:hover {
          border-color: $prh-light-gray;
          color: $prh-dark-gray-87;
        }
      }
      .nav-link.active {
        border-color: $prh-blue;
        color: $white;
        &:hover {
          background-color: $ytj-bluish-green !important;
          border-color: $prh-blue;
          color: $white;
        }
      }
    }
  }
}

.nav-tabs {
  font-family: "FagoWebPro-Medium";
  font-size: 0.8rem;
  width: 100%;
  @media (min-width: 40rem) {
    margin-left: -2rem;
    width: calc(100% + 4rem);
  }
  .nav-link {
    color: $prh-dark-gray-87;
    font-family: $font-family-base;
    border: 1px solid $prh-light-gray;
    text-decoration: none;
    background-color: $white;
    padding: 0.75rem 0;
    position: relative;
    &.arrow {
      font-size: 1.2rem;
      line-height: 1.1rem;
      font-weight: 400;
    }
    &.active {
      border-color: transparent;
      background-color: $prh-blue;
      color: $white;
    }
    &:focus {
      box-shadow: 0px 0px 0px 2px rgba($prh-bright-blue, 1) !important;
      border-color: white;
      z-index: 1;
    }

    &:disabled,
    &:disabled:hover,
    &:disabled:active,
    &.disabled,
    &.disabled:hover,
    &.disabled:active {
      color: $prh-dark-gray-50;
      border: 1px solid $prh-light-gray;
    }
    &:hover,
    &:active {
      text-decoration: none;
      border: 1px solid $prh-purple;
      color: $white;
      background-color: $prh-purple;
    }
    &.error {
      color: $prh-orange;
      border: 1px solid $danger;
      @media (min-width: 768px) {
        padding-right: 20px;
      }
    }
    &.error:before {
      position: absolute;
      top: 0.7rem;
      right: 0.6rem;
      content: "";
      width: 1.2rem;
      height: 1.2rem;
      background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="%23C85204" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-alert-circle"%3E%3Ccircle cx="12" cy="12" r="10"%3E%3C/circle%3E%3Cline x1="12" y1="8" x2="12" y2="12"%3E%3C/line%3E%3Cline x1="12" y1="16" x2="12.01" y2="16"%3E%3C/line%3E%3C/svg%3E');
      background-size: contain;
      background-repeat: no-repeat;
      @media only screen and (max-width: 768px) {
        display: none;
      }
    }
    &.error:hover {
      color: $white;
      border: 1px solid $prh-purple;
      &.error:before {
        position: absolute;
        top: 0.7rem;
        right: 0.6rem;
        content: "";
        width: 1.2rem;
        height: 1.2rem;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="%23fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-alert-circle"%3E%3Ccircle cx="12" cy="12" r="10"%3E%3C/circle%3E%3Cline x1="12" y1="8" x2="12" y2="12"%3E%3C/line%3E%3Cline x1="12" y1="16" x2="12.01" y2="16"%3E%3C/line%3E%3C/svg%3E');
      }
      @media only screen and (max-width: 768px) {
        &:after {
          position: fixed;
          font-weight: bolder;
          content: "!";
          width: 16px;
          height: 16px;
        }
      }
    }
    &.error:after {
      @media only screen and (max-width: 768px) {
        position: fixed;
        font-weight: bolder;
        content: "!";
        margin-left: 5px;
        width: 16px;
        height: 16px;
      }
      @media only screen and (max-width: 767px) {
        margin-left: 5px;
      }
      @media only screen and (max-width: 400px) {
        margin-left: -1px;
      }
    }
    &.success {
      color: $prh-green;
      border-color: $prh-green;
      @media (min-width: 768px) {
        padding-right: 20px;
      }
      &:hover {
        color: $white;
        border-color: $prh-purple;
      }
      /* Making dynamically colourable success icon with a circle */
      $icon-str-start: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="';
      $icon-color: "%23" + str-slice(#{$prh-green}, -6);
      $icon-str-end: '" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check-circle"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>';
      &:before {
        position: absolute;
        top: 0.7rem;
        right: 0.6rem;
        content: "";
        width: 1.2rem;
        height: 1.2rem;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml," + $icon-str-start + $icon-color + $icon-str-end);
        @media only screen and (max-width: 768px) {
          display: none;
        }
      }
      &:hover::before {
        $icon-color: "%23" + str-slice(#{"ffffff"}, -6);
        background-image: url("data:image/svg+xml," + $icon-str-start + $icon-color + $icon-str-end);
      }
      @media only screen and (max-width: 768px) {
        /* Making dynamically colourable success icon */
        $icon-str-start: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="';
        $icon-color: "%23" + str-slice(#{$prh-green}, -6);
        $icon-str-end: '" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>';
        &:after {
          position: absolute;
          top: 0.8rem;
          right: 0.1rem;
          content: "";
          width: 0.9rem;
          height: 0.9rem;
          background-size: contain;
          background-repeat: no-repeat;
          background-image: url("data:image/svg+xml," + $icon-str-start + $icon-color + $icon-str-end);
        }
        &:hover::after {
          $icon-color: "%23" + str-slice(#{"ffffff"}, -6);
          background-image: url("data:image/svg+xml," + $icon-str-start + $icon-color + $icon-str-end);
        }
      }
    }
  }
}
.step-indicator {
  .nav-link {
    border-color: $prh-blue;
    color: $prh-blue;
    &:hover {
      background-color: inherit !important;
      color: $prh-purple;
    }
    &:focus {
      color: $prh-purple;
      box-shadow: 0px 0px 0px 2px $white, inset 0px 0px 0px 2px $prh-blue !important;
      border-color: $prh-blue;
      outline: 0px;
    }
    &.disabled:focus {
      color: $prh-dark-gray-50;
      box-shadow: none !important;
      border-color: $prh-light-gray;
      outline: 0px;
    }
    &.active {
      &:hover,
      &:active,
      &:focus {
        background-color: $prh-purple !important;
        color: $white;
      }
      &:active,
      &:focus {
        box-shadow: 0px 0px 0px 2px $prh-blue, inset 0px 0px 0px 1px $white !important;
        border-color: $white;
      }
    }
    &.success {
      color: $prh-green;
      &:hover {
        color: $prh-purple;
      }
      &:hover::before {
        $icon-str-start: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="';
        $icon-color: "%23" + str-slice(#{$prh-green}, -6);
        $icon-str-end: '" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check-circle"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>';
        background-image: url("data:image/svg+xml," + $icon-str-start + $icon-color + $icon-str-end);
      }
      @media only screen and (max-width: 768px) {
        &:hover::after {
          $icon-str-start: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="';
          $icon-color: "%23" + str-slice(#{$prh-green}, -6);
          $icon-str-end: '" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>';
          background-image: url("data:image/svg+xml," + $icon-str-start + $icon-color + $icon-str-end);
        }
      }
    }
    &.error {
      &:hover {
        color: $prh-purple;
      }
      &:hover.error:before {
        background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="%23C85204" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-alert-circle"%3E%3Ccircle cx="12" cy="12" r="10"%3E%3C/circle%3E%3Cline x1="12" y1="8" x2="12" y2="12"%3E%3C/line%3E%3Cline x1="12" y1="16" x2="12.01" y2="16"%3E%3C/line%3E%3C/svg%3E');
      }
      &:hover:after {
        color: $prh-orange;
      }
    }
  }
  span.nav-link.active {
    &:active {
      background-color: $prh-blue !important;
      border-color: $prh-blue;
      box-shadow: none !important;
    }
  }
  .static-item {
    text-align: center;
    .nav-link {
      border-color: $prh-light-gray;
      color: $prh-dark-gray-87;
      &:hover,
      &.success:hover {
        border-color: $prh-light-gray;
        color: $prh-dark-gray-87;
      }
    }
    .nav-link.active {
      border-color: $prh-blue;
      color: $white;
      &:hover {
        background-color: $prh-blue !important;
        border-color: $prh-blue;
        color: $white;
      }
    }
  }
}
