/*
  Footer 
  for Bootstrap 4.0
  ------------------
  PRH Style Guide

*/

.theme-ytj {
  .prh-site-footer {
    background-color: $ytj-bluish-green;
    a:not(.btn) {
      color: $ytj-turquoise10;
    }
    h4,
    .h4 {
      color: $ytj-turquoise10;
    }
    a:hover {
      background-color: $ytj-turquoise;
    }
    .btn-icon:active,
    .btn-primary:active {
      border-color: $white !important;
    }
  }
}

.prh-site-footer {
  padding: 1em 1em 0 1em;
  background-color: $prh-blue;
  bottom: 0;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-wrap: wrap;
    flex-direction: column;
  }
  @media (min-width: 768px) {
    flex-wrap: wrap;
    flex-direction: row;
  }
  .container {
    max-width: $container-max-width;
  }
  p,
  legend,
  small,
  .small {
    color: $white;
    font-family: "FagoWebPro-Medium";
    font-weight: 300;
    margin-bottom: 0.5rem;
  }
  a:not(.btn) {
    color: $prh-pale-blue;
    text-decoration: underline;
  }
  a:hover {
    background-color: $prh-bright-blue;
  }
  a:focus {
    outline: 2px solid white;
  }
  .btn-icon:hover,
  .btn-icon:focus {
    background-color: $prh-purple;
  }
  .btn-icon:hover,
  .btn-icon:focus,
  .form-control:focus {
    /*outline: 2px solid white;*/
    box-shadow: 0px 0px 0px 3px rgba($white, 1) !important;
    border: 1px solid $prh-blue;
    position: relative;
    z-index: 1;
  }
  .btn-icon:active,
  .btn-primary:active {
    border-color: $prh-blue !important;
  }
  .form-control:focus {
    box-shadow: inset 0px 0px 0px 1px $prh-blue;
  }
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4 {
    color: $prh-pale-blue;
    font-size: 1.25rem;
  }
  .prh-title {
    font-family: "FagoWebPro-CondBold";
    text-transform: uppercase;
    font-weight: 300;
    color: $white;
  }
}

.prh-site-footer__logo {
  width: 4rem;
  height: 4rem;
  margin: 0;
  background-image: url("../images/logo.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.prh-site-footer__link {
  text-decoration: none;
  font-weight: normal;
  &:focus,
  &:hover,
  &:active {
    color: $white;
    background-color: $prh-bright-blue;
    .prh-icon {
      stroke: $white;
    }
  }
}
