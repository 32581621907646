/*
  Icons
  for Bootstrap 4.0
  -------------------
  PRH Style Guide

*/

.theme-ytj {
  a:active {
    .badge-overlay {
      background-color: $ytj-purple;
    }
    .prh-icon--blue {
      stroke: $ytj-purple;
    }
  }
  .prh-icon {
    stroke: $ytj-bluish-green;
    a:hover &,
    a:active &,
    .btn:hover &,
    .btn:active & {
      stroke: $ytj-purple !important;
    }
    &.icon-info {
      stroke: $prh-blue; // (Ticket: DS-215) When icon --> info, always use prh-blue.
    }
  }
  a:hover {
    .prh-icon--blue {
      stroke: $ytj-purple;
    }
    .badge-overlay {
      background-color: $ytj-purple;
    }
  }
  .prh-icon--blue {
    stroke: $ytj-bluish-green;
    a:hover &,
    a:active &,
    .btn:active &,
    .btn:focus & {
      stroke: $ytj-purple;
    }
  }
  .prh-icon--button {
    border-color: $ytj-turquoise;
    a:hover &,
    a:active &,
    .btn:hover &,
    .btn:active & {
      stroke: $white;
    }
    &:hover,
    &:active {
      border-color: $ytj-purple;
    }
  }
  .prh-icon--white {
    stroke: $white !important;
    a:hover &,
    a:active &,
    .btn:hover &,
    .btn:active & {
      stroke: $white;
    }
  }
  // Used in Alert Danger icon, does not have a (ytj) replacing color. So this makes sure it stays $prh-orange
  .prh-icon--orange {
    stroke: $prh-orange;
  }
  .prh-icon--green {
    stroke: $prh-green;
  }

  .prh-custom-icon {
    .st1,
    .st2-find {
      fill: $ytj-bluish-green;
    }
  }
}

.prh-icon {
  height: 2.2rem;
  width: 2.2rem;
  padding: 0 0.1rem 0.1rem 0.1rem;
  stroke: $prh-blue;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
  a:hover &,
  a:focus &,
  a:active &,
  .btn:hover &,
  .btn:focus &,
  .btn:active & {
    stroke: $prh-purple;
  }
}

.prh-icon--white {
  stroke: $white;
  a:hover &,
  a:active &,
  .btn:hover &,
  .btn:active & {
    stroke: $white;
  }
}

.prh-icon--blue {
  stroke: $prh-blue;
  a:hover &,
  a:active &,
  .btn:active &,
  .btn:focus & {
    stroke: $prh-purple;
  }
  .btn:active & {
    stroke: $white;
  }
}

.prh-icon--orange {
  stroke: $prh-orange;
  a:hover &,
  a:active &,
  .btn:hover &,
  .btn:active & {
    stroke: lighten($prh-orange, 10%);
  }
}

.prh-icon--green {
  stroke: $prh-green;
  a:hover &,
  a:active &,
  .btn:hover &,
  .btn:active & {
    stroke: lighten($prh-green, 10%);
  }
}

.prh-icon--xs {
  height: 1rem;
  width: 1rem;
  padding: 0;
  padding-bottom: 0.1rem;
}

.prh-icon--sm {
  height: 1.4rem;
  width: 1.4rem;
}

.prh-icon--m {
  height: 2.5rem;
  width: 2.5rem;
}

.prh-icon--xl {
  width: 3.2rem;
  height: 3.2rem;
  padding: 0 0.25rem 0.25rem 0.25rem;
}

.prh-icon--login {
  width: 62px;
  height: 62px;
  margin: 0.25rem;
}

.prh-icon--full {
  width: 100%;
  height: auto;
}

.prh-icon--nav {
  width: 1.5em;
}

.prh-icon--button {
  border-color: $prh-bright-blue;
  font-family: "FagoWebPro-Medium";
  font-weight: 500;
  margin-bottom: 6px;
  margin-top: 6px;
  stroke: $white;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
  a:hover &,
  a:active &,
  .btn:hover &,
  .btn:active & {
    stroke: $white;
  }
  &:hover,
  &:active {
    border-color: $prh-purple;
  }
  &:disabled,
  &.disabled {
    background-color: $prh-dark-gray-50;
    border-color: $prh-dark-gray-50;
  }
}
