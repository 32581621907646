.theme-ytj {
  .prh-site-header {
    background-color: $ytj-bluish-green;
    .prh-site-header__separator {
      @media (min-width: 767px) {
        border-top: 1px solid $ytj-turquoise50;
      }
    }
    .navbar-user-button-container {
      border-left: 1px solid $ytj-turquoise50;
      @media only screen and (max-width: 995px) {
        border-top: 1px solid $ytj-turquoise50;
        border-left: none;
      }
    }

    .logged-in-user-container {
      .logged-in-icon {
        stroke: $ytj-turquoise50;
      }
    }
    .navbar-collapse {
      /* Tablet Navbar */
      /* Mobile Navbar */
      @media only screen and (max-width: 767px) {
        border-top: 1px solid $ytj-turquoise50;
        .btn.nav-link {
          text-align: left;
          padding: 1rem 0.5rem;
        }
      }
      .language-separator {
        @media (min-width: 768px) {
          border-left: 1px solid $ytj-turquoise50;
        }
      }
      /* Button in navbar */
      .navbar-button {
        @media only screen and (max-width: 786px) {
          border-top: 1px solid $ytj-turquoise50;
        }
        @media only screen and (min-width: 1024px) {
          border-left: 1px solid $ytj-turquoise50;
        }

        @media only screen and (min-width: 768px) and (max-width: 1023px) {
          //border-left: 1px solid $prh-bright-blue;
          border-top: 1px solid $ytj-turquoise50;
          color: $ytj-turquoise50;
        }
      }
    }
    .nav-link:focus {
      @media (min-width: 768px) {
        background: $ytj-turquoise50;
      }
      @media (max-width: 767px) {
        background-color: $ytj-turquoise50;
      }
    }
    .nav-link.active {
      @media (min-width: 768px) {
        background: $ytj-turquoise50;
      }
      @media (max-width: 767px) {
        background-color: $ytj-turquoise50;
      }
    }
  }
  .prh-collapse-header {
    @media (max-width: 767px) {
      border-bottom: 1px solid $ytj-turquoise50;
    }
  }
  .subheader-logout-button {
    a:not(:hover):not(:active) {
      color: $ytj-bluish-green;
    }
  }
  .nav-item:focus {
    outline: 2px solid $ytj-bluish-green;
  }
}

.prh-site-header {
  z-index: 1;
  background-color: $prh-blue;
  width: 100%;
  flex-direction: column;
  .prh-site-header__container,
  .prh-site-header__title h2 {
    color: $white;
  }
  .prh-site-header__separator {
    @media (min-width: 767px) {
      border-top: 1px solid $prh-bright-blue;
    }
  }
  .navbar-user-button-container {
    border-left: 1px solid $prh-bright-blue;
    display: flex;
    align-items: center;
    height: 100%;
    @media only screen and (max-width: 995px) {
      border-top: 1px solid $prh-bright-blue;
      border-left: none;
      height: auto;
    }
  }
  .logged-in-user-container {
    flex: 1 0 auto;
    @media only screen and (max-width: 1024px) {
      border-left: none;
      height: auto;
      display: inline-flex;
    }
    .logged-in-icon {
      padding: 0 0.1rem 0.1rem 0.1rem;
      stroke: $prh-bright-blue;
      stroke-width: 2;
      stroke-linecap: round;
      stroke-linejoin: round;
      fill: none;
      height: 1.4rem;
      width: 1.4rem;
      stroke: #fff;
      margin-left: 1.5rem;
      @media only screen and (min-width: 1024px) {
        height: 1.4rem;
        width: 1.4rem;
      }
      @media only screen and (max-width: 1024px) {
        margin-left: 0.3rem;
      }
    }
  }
  .navbar {
    width: 100%;
    align-items: stretch;
    color: $white;
    padding: 0 1rem;
    justify-content: space-between;
  }
  .navbar-collapse {
    @media only screen and (min-width: 1024px) {
    }
    /* Tablet Navbar */
    @media only screen and (min-width: 768px) and (max-width: 1023px) {
      -webkit-hyphens: auto;
      -moz-hyphens: auto;
      -ms-hyphens: auto;
      hyphens: auto;
      overflow-wrap: break-word;
    }
    /* Mobile Navbar */
    @media only screen and (max-width: 767px) {
      border-top: 1px solid $prh-bright-blue;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      -webkit-hyphens: auto;
      -moz-hyphens: auto;
      -ms-hyphens: auto;
      hyphens: auto;
      overflow-wrap: break-word;
      .btn.nav-link {
        text-align: left;
        padding: 1rem 0.5rem;
      }
    }
    .navbar-collapse__primary_menu {
      align-items: left;
    }
    .nav-item {
      padding: 1rem 0.4rem 1rem 0rem;
      text-decoration: none;
      margin-top: 3px;
    }
    .nav-item,
    .dropdown-item {
      font-family: $font-family-base;
      @media (max-width: 1024px) {
        padding: 0.5rem 0 0.5rem 0;
      }
      &:focus-within {
        visibility: visible;
        opacity: 1;
        display: block;
      }
      .nav-link {
        text-decoration: none;
        padding: 0.5rem 0.5rem;
      }
    }
    .dropdown-menu {
      @media (max-width: 1023px) {
        margin: 1rem -1.5rem;
        padding: 2em;
      }
    }
    .language-separator {
      @media (max-width: 1024px) {
        display: none;
      }
      @media (min-width: 768px) {
        height: 30px;
        border-left: 1px solid $prh-bright-blue;
        margin-left: 5px;
        margin-right: 5px;
      }
    }
    /* Button in navbar */
    .navbar-button {
      align-items: center;
      margin-left: 0.5rem;
      display: flex;
      text-decoration: none;
      color: $white;

      @media only screen and (max-width: 786px) {
        border-top: 1px solid $prh-bright-blue;
        padding: 0.5em 0 0.5em 0;
      }
      @media only screen and (min-width: 1024px) {
        height: 100%;
        margin-top: -1px;
        border-left: 1px solid $prh-bright-blue;
        padding: 1em 0 1em 1rem;
        white-space: nowrap;
      }

      @media only screen and (min-width: 768px) and (max-width: 1023px) {
        //border-left: 1px solid $prh-bright-blue;
        border-top: 1px solid $prh-bright-blue;
        color: $prh-bright-blue;
        padding: 0.5em 0 0.5em 1rem;
        span {
          padding: 0;
          margin-left: 0;
        }
        svg {
          display: none;
        }
      }

      .prh-icon {
        margin-left: 0;
        margin-right: 0;
      }
      &.with-logged-in-user {
        border-left: none;
        @media only screen and (max-width: 768px) {
          border-top: none;
          display: inline-flex;
        }
      }
    }
  }
  /* Active states in navbar */
  .nav-link:hover {
    @media (min-width: 768px) {
      stroke-opacity: 80%;
      -webkit-box-shadow: inset 0px -3px 0px 0px rgba(255, 255, 255, 1);
      -moz-box-shadow: inset 0px -3px 0px 0px rgba(255, 255, 255, 1);
      box-shadow: inset 0px -3px 0px 0px rgba(255, 255, 255, 1);
      transition: 0.3s ease-in-out;
    }
  }
  .nav-link:focus {
    text-decoration: none;
    background: $prh-bright-blue;
    -webkit-box-shadow: inset 0px 0px 0px 2px rgba(255, 255, 255, 1);
    -moz-box-shadow: inset 0px 0px 0px 2px rgba(255, 255, 255, 1);
    box-shadow: inset 0px 0px 0px 2px rgba(255, 255, 255, 1),
      inset 0px -3px 0px 0px rgba(255, 255, 255, 1) !important;
    .prh-icon--white {
      stroke: $white;
    }
  }
  .navbar-brand:focus,
  .navbar-toggler:focus {
    outline: 2px solid white;
  }
  .nav-link.active {
    text-decoration: none;
    background: $prh-bright-blue;
    -webkit-box-shadow: inset 0px 0px 0px 2px rgba(255, 255, 255, 1);
    -moz-box-shadow: inset 0px 0px 0px 2px rgba(255, 255, 255, 1);
    box-shadow: inset 0px 0px 0px 2px rgba(255, 255, 255, 1),
      inset 0px -3px 0px 0px rgba(255, 255, 255, 1) !important;
    padding: 0.5em;
    background-color: $prh-bright-blue;
  }
}
/* X icon for open navbar */

.navbar-toggler.collapsed > .close,
.navbar-toggler:not(.collapsed) > .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='28' viewBox='0 0 24 24' fill='none' stroke='%23FFFFFF' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-x'%3E%3Cline x1='18' y1='6' x2='6' y2='18'%3E%3C/line%3E%3Cline x1='6' y1='6' x2='18' y2='18'%3E%3C/line%3E%3C/svg%3E");
}

/* Accordion icon */
.prh-accordion {
  background-repeat: no-repeat;
  background-position: 98.5% 50%;
  background-size: 1rem;
  position: relative;
  /* this will work with btn-primary and can be the default colour (white) */
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23FFFFFF' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-up'%3E%3Cpolyline points='18 15 12 9 6 15'%3E%3C/polyline%3E%3C/svg%3E");
  &.collapsed {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23FFFFFF' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  }
  /* set non-default and dynamic colour for btn-secondary */
  $icon-str-start: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="';
  $icon-color: "%23" + str-slice(#{$prh-blue}, -6);
  $icon-str-end: '" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-up"><polyline points="18 15 12 9 6 15"></polyline></svg>';
  &.btn-link {
    text-decoration: underline;
  }
  &.btn-link,
  &.btn-secondary {
    background-image: url("data:image/svg+xml," + $icon-str-start + $icon-color + $icon-str-end);
    &:focus,
    &:hover {
      $icon-color: "%23" + str-slice(#{$prh-purple}, -6);
      background-image: url("data:image/svg+xml," + $icon-str-start + $icon-color + $icon-str-end);
      color: $prh-purple;
    }
    &.collapsed {
      $icon-color: "%23" + str-slice(#{$prh-blue}, -6);
      $icon-str-end: '" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>';
      background-image: url("data:image/svg+xml," + $icon-str-start + $icon-color + $icon-str-end);
      &:focus,
      &:hover {
        $icon-color: "%23" + str-slice(#{$prh-purple}, -6);
        background-image: url("data:image/svg+xml," + $icon-str-start + $icon-color + $icon-str-end);
        color: $prh-purple;
      }
    }
  }
  @media (max-width: 1023px) {
    background-position-x: 97%;
  }
  @media (max-width: 768px) {
    background-position-x: 95%;
  }
}

.prh-site-header-small {
  top: 0;
}

.navbar-toggler__shoppingcart {
  float: right;
}

.navbar-expand-true .navbar-collapse.show {
  height: 100%;
}

.prh-site-header__main-navigation {
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  @media only screen and (min-width: 1024px) {
    align-items: center;
  }
  @media (max-width: 767px) {
    align-items: left;
  }
  /* Tablet Navbar */
  @media only screen and (max-width: 1023px) {
    align-items: left !important;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    overflow-wrap: break-word;
    //span {
    // This used to hide text from svg icos. But there was a decision to make all navbars toggle to mobileDisblay when tablet size is reached
    //display: none;
    //}
  }
}

.prh-site-header__container,
.prh-sub-header__container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  max-width: $container-max-width;
  padding: 0;
  .nav-link > .prh-sub-header {
    border-bottom: none;
  }
}

.prh-site-header__title {
  @media (max-width: 767px) {
    span {
      display: none;
    }
  }
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  span {
    font-size: $h3-font-size;
    margin: 0;
    color: $white;
  }
  span.sub-title {
    font-family: "FagoWebPro-CondBold";
    display: block;
    font-size: 1rem;
    text-transform: uppercase;
  }
}
/* Header styling for collapsed menu */

.prh-collapse-header {
  @media (min-width: 767px) {
    display: none;
  }
  @media (max-width: 767px) {
    border-bottom: 1px solid $prh-bright-blue;
    padding-top: 1rem;
    padding-bottom: 1.5rem;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    span {
      font-family: $font-family-base;
      font-size: $h3-font-size;
      margin: 0;
      color: $white;
    }
    span.sub-title {
      font-family: "FagoWebPro-CondBold";
      display: block;
      font-size: 1rem;
      text-transform: uppercase;
    }
  }
}

/*
  Site Header
  for Bootstrap 4.0
  -------------------
  PRH Style Guide

*/

.prh-site-header__title_twotogglers {
  display: flex;
  @media (max-width: 767px) {
    h2 {
      display: none;
    }
  }
  h2 {
    font-size: $h3-font-size;
    color: $white;
    margin: 0;
  }
  span.sub-title {
    display: block;
    font-size: 0.8rem;
    text-transform: uppercase;
    letter-spacing: 0.06rem;
  }
  > button:focus {
    outline: 2px solid white;
  }
}

.prh-site-header__logo {
  width: 4rem;
  height: 3rem;
  margin-right: 1rem;
  background-image: url("../images/logo.png");
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.prh-sub-header {
  background-color: $white;
  font-size: 0.9rem;
  padding: 0.25rem 0;
  border-bottom: 1px solid $prh-medium-gray;
  box-shadow: 0 3px 3px -2px $prh-medium-gray;
  text-decoration: none;
  .breadcrumb {
    padding-left: 0;
  }
}

.subheader-logout-button {
  font-family: $font-family-base;
  padding: 0.5rem 0.5rem 0.5rem 0rem;
  a:not(:hover):not(:active) {
    color: $prh-blue;
  }
  .prh-icon {
    margin-right: 0.2rem;
  }
}

.prh-site-header__subnavigation {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

/* remove this if "Login landing page" gets removed */
.prh-section-borders a {
  text-decoration: underline;
}

/* Skip links
for screen reader 
  -------------------
  PRH Style Guide

*/

.nav-item:focus {
  outline: 2px solid $prh-blue;
}

.prh-skip-link {
  background: $white;
  height: auto;
  left: 0;
  padding: 2px;
  position: absolute;
  transform: translateY(-100%);
}

.prh-skip-link:focus {
  transform: translateY(0%);
}
