/*
  List Items
  for Bootstrap 4.0
  -------------------
  PRH Style Guide

*/

.prh-list-item {
  display: flex;
  position: relative;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 1rem 2rem;
}

.prh-list-item__data {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  @media (min-width: 50rem) {
    width: 50%;
    max-width: 30rem;
  }
}

.theme-ytj {
  .prh-list-item__title {
    h3 a {
      color: $ytj-bluish-green;
    }
    h3 a:hover,
    h3 a:active {
      color: $ytj-purple;
    }
  }
}

.prh-list-item__title {
  order: 1;
  flex-grow: 1;
  h3 a {
    color: $prh-blue;
    font-size: $h4-font-size;
    text-decoration: underline;
  }
  h3 a:hover,
  h3 a:active {
    color: $prh-purple;
  }
}

.prh-list-item__features {
  order: 2;
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-grow: 1;
  max-width: 40rem;
  width: 100%;
  span {
    margin-right: 1rem;
    color: $prh-dark-gray-87;
  }
}

.prh-list-item__controls {
  align-self: flex-end;
  @media (min-width: 50rem) {
    position: absolute;
    right: 0.5rem;
    top: 0;
  }
}

.prh-list-item__status {
  padding-left: 3rem;
  display: block;
  max-width: 30rem;
  p {
    margin: 0;
  }
  @media (max-width: 768px) {
    padding-top: 1rem;
  }
}

.prh-list-item__status-title {
  font-style: italic;
  &.error {
    font-style: normal;
    font-family: "FagoWebPro-Medium";
    font-weight: 500;
    position: relative;
  }
  &.error:after {
    position: absolute;
    top: -0.25rem;
    left: -2.25rem;
    content: "";
    width: 1.8rem;
    height: 1.8rem;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23C85204' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-alert-circle'%3E%3Ccircle cx='12' cy='12' r='10'%3E%3C/circle%3E%3Cline x1='12' y1='8' x2='12' y2='12'%3E%3C/line%3E%3Cline x1='12' y1='16' x2='12.01' y2='16'%3E%3C/line%3E%3C/svg%3E");
    background-size: contain;
  }
}
