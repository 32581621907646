/*
  Jumbotron
  for Bootstrap 4.0
  -------------------
  PRH Style Guide

*/

/* Use this class to pull the first section over the jumbotron */

.theme-ytj {
  .jumbotron .lead {
    color: $ytj-bluish-green;
  }
}

.jumbotron-flow-under {
  margin-bottom: -5rem;
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.jumbotron .lead {
  color: $prh-blue;
}

.jumbotron-flow-under_max {
  margin-bottom: -10rem;
  padding-top: 5rem;
  padding-bottom: 5rem;
}
