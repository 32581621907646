/*
  Modal 
  for Bootstrap 4.0
  ------------------
  PRH Style Guide

*/

.modal-content {
  box-shadow: 3px 3px 5px rgba($prh-dark-gray, 0.15%);
}

.modal-header {
  padding-bottom: 0;
  /* Give margin to first element but not for the close button */
  & > *:first-child:not(.close) {
    margin-top: 1rem;
  }
  button:focus {
    outline: 2px solid $prh-blue;
  }
}

.modal-body {
  padding-top: 0;
  padding-bottom: 0;
}

.modal-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 1.5rem 2rem;
  border-top: 1px solid transparent;
  border-bottom-right-radius: 0.1rem;
  border-bottom-left-radius: 0.1rem;
}
