/*
  Typography
  for Bootstrap 4.0
  -------------------
  PRH Style Guide

*/

/* Set up the base font sizes */

html {
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @media (min-width: 640px) {
    font-size: 17px;
  }
}

.theme-ytj {
  p > a.link-icon,
  ol a.link-icon,
  ul a.link-icon,
  label a.link-icon {
    &::after {
      display: inline-block;
      background-repeat: no-repeat;
      background-size: 1rem 1rem;
      width: 1rem;
      height: 1rem;
      margin-left: 0.25rem;
      margin-right: 0.25rem;
      vertical-align: -0.125rem;
    }
  }

  h1 > a:focus,
  h2 > a:focus,
  h3 > a:focus,
  h4 > a:focus,
  h5 > a:focus,
  h6 > a:focus,
  p > a:focus,
  div > a:focus,
  ol a:focus,
  ul a:focus,
  label a:focus {
    outline: 2px solid $ytj-bluish-green;
  }

  p > a.link-internal,
  ol a.link-internal,
  ul a.link-internal,
  label a.link-internal {
    /* Making chevre icon */
    $icon-str-start: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="';
    $icon-color: "%23" + str-slice(#{#106477}, -6); // #106477 is $ytj-bluish-green. For some reason using that variable does not work.
    &:hover::after {
      $icon-color: "%23" + str-slice(#{#ae3f89}, -6); // #ae3f89 is $ytj-purple.
    }
  }

  p > a.link-external,
  ol a.link-external,
  ul a.link-external,
  label a.link-external {
    /* Making external link icon */
    $icon-str-start: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="';
    $icon-color: "%23" + str-slice(#{#106477}, -6); //#106477 is $ytj-bluish-green. For some reason using that variable does not work.
    $icon-str-end: '" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-external-link"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg>';
    &::after {
      content: "";
      background-image: url("data:image/svg+xml," + $icon-str-start + $icon-color + $icon-str-end);
    }
    &:hover::after {
      $icon-color: "%23" + str-slice(#{#ae3f89}, -6);
      background-image: url("data:image/svg+xml," + $icon-str-start + $icon-color + $icon-str-end);
    }
  }
}

/* Native font stack for paragraphs and inputs */

p,
input,
legend,
textarea,
li,
dd {
  font-family: $font-family-native;
  font-weight: 400;
  font-size: $font-size-base;
}

/* Set up elements that use the heading font */

.lead,
dt {
  font-family: "FagoWebPro-Medium";
  font-weight: 500;
}

/* Make only body text links underlined */

p > a,
ol a,
ul a,
label a {
  text-decoration: underline;
}

p > a:hover,
ol a:hover,
ul a:hover,
label a:hover {
  /*text-decoration: none;*/
}

h1 > a:focus,
h2 > a:focus,
h3 > a:focus,
h4 > a:focus,
h5 > a:focus,
h6 > a:focus,
p > a:focus,
div > a:focus,
ol a:focus,
ul a:focus,
label a:focus {
  outline: 2px solid $prh-blue;
  color: $prh-purple;
}
.bg-primary {
  a:focus {
    outline: 2px solid white;
  }
}

/* Internal/external link styles
 * - constructing the icons with sass variables to be able
 *   to alter the colour dynamically
 * - the colour code needs to be svg-escaped: # -> %23
 * - splitting the svg string, casting the colour variable,
 *   substringing and concatenating 
 */
ol a.link-icon,
ul a.link-icon,
label a.link-icon {
  font-weight: 600;
}
p > a.link-icon,
ol a.link-icon,
ul a.link-icon,
label a.link-icon {
  &::after {
    display: inline-block;
    background-repeat: no-repeat;
    background-size: 1rem 1rem;
    width: 1rem;
    height: 1rem;
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    vertical-align: -0.125rem;
  }
}
p > a.link-internal,
ol a.link-internal,
ul a.link-internal,
label a.link-internal {
  /* Making chevre icon */
  $icon-str-start: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="';
  $icon-color: "%23" + str-slice(#{$prh-blue}, -6);
  $icon-str-end: '" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"></polyline></svg>';
  &::after {
    content: "";
    background-image: url("data:image/svg+xml," + $icon-str-start + $icon-color + $icon-str-end);
  }
  &:hover::after,
  &:focus::after,
  &:active::after {
    $icon-color: "%23" + str-slice(#{$prh-purple}, -6);
    background-image: url("data:image/svg+xml," + $icon-str-start + $icon-color + $icon-str-end);
  }
}
p > a.link-external,
ol a.link-external,
ul a.link-external,
label a.link-external {
  /* Making external link icon */
  $icon-str-start: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="';
  $icon-color: "%23" + str-slice(#{$prh-blue}, -6);
  $icon-str-end: '" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-external-link"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg>';
  &::after {
    content: "";
    background-image: url("data:image/svg+xml," + $icon-str-start + $icon-color + $icon-str-end);
  }
  &:hover::after,
  &:focus::after,
  &:active::after {
    $icon-color: "%23" + str-slice(#{$prh-purple}, -6);
    background-image: url("data:image/svg+xml," + $icon-str-start + $icon-color + $icon-str-end);
  }
}

/*
 *  Smaller size for headings in mobile
 */

@media (max-width: 768px) {
  h1,
  .h1 {
    font-size: $font-size-base * 1.85;
  }
  h2,
  .h2 {
    font-size: $font-size-base * 1.5;
  }
  h3,
  .h3 {
    font-size: $font-size-base * 1.21;
  }
  h4,
  .h4 {
    font-size: $font-size-base * 1.1;
    &.small {
      font-size: $font-size-base * 0.9;
    }
  }
  h5,
  .h5 {
    font-size: $font-size-base * 1.05;
  }
  h6,
  .h6 {
    font-size: $font-size-base;
  }
}

/* Used in forms to label the form sections */

h4.label,
.prh-label {
  color: $prh-dark-gray-87;
  font-family: $font-family-base;
  font-size: 1rem;
  margin-bottom: 0.1rem;
}

h5 {
  font-family: "FagoWebPro-CondBold";
  letter-spacing: 0.1em;
}

h6 {
  font-family: "FagoWebPro-CondBold";
}

/* Hide screen reader text */

.screen-reader-text {
  @include sr-only;
}
