/*
  Breadcrumbs
  for Bootstrap 4.0
  -------------------
  PRH Style Guide

*/

.theme-ytj {
  .breadcrumb-item {
    a:not(:hover):not(:active) {
      color: $ytj-bluish-green;
    }
  }
}

.breadcrumb-item {
  font-family: $font-family-base;
  a {
    text-decoration: underline;
  }
  /*a:not(:hover):not(:active) {
    color: $prh-blue;
  }*/
  a:hover,
  a:focus {
    text-decoration: underline;
    color: $prh-purple;
    .prh-icon {
      stroke: $prh-purple;
    }
  }
  .prh-icon {
    margin-right: 0.5rem;
  }
}
